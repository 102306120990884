exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-mince-pie-js": () => import("./../../../src/pages/mince-pie.js" /* webpackChunkName: "component---src-pages-mince-pie-js" */),
  "component---src-pages-quotes-random-js": () => import("./../../../src/pages/quotes/random.js" /* webpackChunkName: "component---src-pages-quotes-random-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-code-js": () => import("./../../../src/templates/code.js" /* webpackChunkName: "component---src-templates-code-js" */),
  "component---src-templates-code-list-js": () => import("./../../../src/templates/code-list.js" /* webpackChunkName: "component---src-templates-code-list-js" */),
  "component---src-templates-image-js": () => import("./../../../src/templates/image.js" /* webpackChunkName: "component---src-templates-image-js" */),
  "component---src-templates-image-list-js": () => import("./../../../src/templates/image-list.js" /* webpackChunkName: "component---src-templates-image-list-js" */),
  "component---src-templates-music-js": () => import("./../../../src/templates/music.js" /* webpackChunkName: "component---src-templates-music-js" */),
  "component---src-templates-music-list-js": () => import("./../../../src/templates/music-list.js" /* webpackChunkName: "component---src-templates-music-list-js" */),
  "component---src-templates-quote-js": () => import("./../../../src/templates/quote.js" /* webpackChunkName: "component---src-templates-quote-js" */),
  "component---src-templates-quote-list-js": () => import("./../../../src/templates/quote-list.js" /* webpackChunkName: "component---src-templates-quote-list-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-writing-list-js": () => import("./../../../src/templates/writing-list.js" /* webpackChunkName: "component---src-templates-writing-list-js" */)
}

